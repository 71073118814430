var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('v-card-title',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"8"}},[_c('BtnCircle',{attrs:{"tLabel":"Atrás","tPosition":"bottom","icon":"mdi-arrow-left","color":"grey darken-3","size":"x-small","to":{
            name: _vm.page_route ? (_vm.page_route + ".detalle") : 'home',
            params: { id: _vm.id },
          }}}),_c('span',{staticClass:"text-h6 ml-1",domProps:{"textContent":_vm._s(
            ((_vm.$route.meta.title) + " " + (!_vm.loading ? ("(" + (_vm.notes.length) + ")") : ''))
          )}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","xs":"12","md":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","loading":_vm.faqs_ldg,"disabled":_vm.faqs.length == 0},on:{"click":function($event){$event.stopPropagation();_vm.faqs_dlg = true}}},on),[_c('v-icon',[_vm._v(" mdi-help ")])],1)]}}])},[_c('span',{domProps:{"textContent":_vm._s('Ayuda')}})]),(
            _vm.$store.getters.getLogin.permissions.rha_administratives.create
          )?_c('BtnCircle',{attrs:{"tLabel":"Agregar","tPosition":"bottom","icon":"mdi-plus","color":"success","size":"x-small","click":_vm.handleDialogAdd,"loading":_vm.loading}}):_vm._e()],1)],1)],1),(!_vm.loading)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('RhaData',{attrs:{"rha_id":_vm.id}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"elevation":"1","dense":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"text-caption",domProps:{"textContent":_vm._s('REGISTROS')}})]),_c('v-spacer'),_c('v-toolbar-items')],1),_c('v-card-text',[_c('v-form',{ref:"form_data",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Fecha")]),_c('th',{staticClass:"text-left"},[_vm._v("Comentario")]),_c('th',{staticClass:"text-left"},[_vm._v("Acción")])])]),_c('tbody',_vm._l((_vm.notes),function(item,i){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.created_at))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',[(
                                _vm.$store.getters.getLogin.permissions
                                  .rha_administratives.update
                              )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"info","dark":""},on:{"click":function($event){return _vm.editNote(item.id)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-pencil')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Editar')}})]):_vm._e(),(
                                _vm.$store.getters.getLogin.permissions
                                  .rha_administratives.delete
                              )?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-1",attrs:{"fab":"","x-small":"","color":"error","dark":""},on:{"click":function($event){return _vm.HandleDesactivate(item.id)}}},on),[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-delete')}})],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s('Eliminar')}})]):_vm._e()],1)])}),0)]},proxy:true}],null,false,3246057664)})],1)],1)],1)],1)],1)],1)],1)],1):_vm._e(),_c('div',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition","scrollable":""},model:{value:(_vm.modal_add),callback:function ($$v) {_vm.modal_add=$$v},expression:"modal_add"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.handleDialogAdd}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',{domProps:{"textContent":_vm._s('Agregar')}}),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":_vm.handleSubmit}},[_vm._v("Aceptar")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form_add",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-textarea',{attrs:{"label":"Comentario"},model:{value:(_vm.note.description),callback:function ($$v) {_vm.$set(_vm.note, "description", $$v)},expression:"note.description"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('FaqDlg',{attrs:{"faqs":_vm.faqs},model:{value:(_vm.faqs_dlg),callback:function ($$v) {_vm.faqs_dlg=$$v},expression:"faqs_dlg"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }