<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.detalle` : 'home',
              params: { id: id },
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${!loading ? `(${notes.length})` : ''}`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="
              $store.getters.getLogin.permissions.rha_administratives.create
            "
            tLabel="Agregar"
            tPosition="bottom"
            icon="mdi-plus"
            color="success"
            size="x-small"
            :click="handleDialogAdd"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="!loading">
      <v-row>
        <v-col cols="12">
          <RhaData :rha_id="id" />
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTROS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-form ref="form_data" lazy-validation>
                <v-row>
                  <v-col cols="12" xs="12" md="12">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Fecha</th>
                            <th class="text-left">Comentario</th>
                            <th class="text-left">Acción</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, i) in notes" :key="item.id">
                            <td>{{ item.created_at }}</td>
                            <td>{{ item.description }}</td>
                            <td>
                              <v-tooltip
                                left
                                v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_administratives.update
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="info"
                                    dark
                                    @click="editNote(item.id)"
                                  >
                                    <v-icon v-text="'mdi-pencil'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Editar'" />
                              </v-tooltip>
                              <v-tooltip
                                left
                                v-if="
                                  $store.getters.getLogin.permissions
                                    .rha_administratives.delete
                                "
                              >
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    class="ml-1"
                                    v-on="on"
                                    fab
                                    x-small
                                    color="error"
                                    dark
                                    @click="HandleDesactivate(item.id)"
                                  >
                                    <v-icon v-text="'mdi-delete'" />
                                  </v-btn>
                                </template>
                                <span v-text="'Eliminar'" />
                              </v-tooltip>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- modal update photo -->
    <div>
      <v-dialog
        v-model="modal_add"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
      >
        <v-card tile>
          <v-toolbar dark>
            <v-btn icon dark @click="handleDialogAdd">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-text="'Agregar'" />
            <v-spacer />
            <v-toolbar-items>
              <v-btn dark text @click="handleSubmit">Aceptar</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-form v-on:submit.prevent ref="form_add" lazy-validation>
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="6">
                    <v-textarea label="Comentario" v-model="note.description" />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
//import functions
//import components
import Axios from "axios";
import { URL_API, headersToken, msgConfirm, msgAlert } from "../../control";
import { show, index, destroy } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import RhaData from "../../components/RhaData.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    RhaData,
    FaqDlg,
  },
  data() {
    return {
      page_route: null,
      page_title: "Notas catastroficas",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: true,
      data: {
        amount_2: 7000.0,
      },
      rules: {},
      values: [
        {
          date: "2021-10-01 12:00:00",
          observation: "Observación 1",
        },
        {
          date: "2021-10-02 12:00:00",
          observation: "Observación 2",
        },
        {
          date: "2021-10-03 12:00:00",
          observation: "Observación 3",
        },
      ],
      modal_add: false,
      notes: [],
      note: {},
      rha: {},
      rha_id: this.$attrs.id,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getNotes() {
      index("rha/catastrophics", this.login.token, [
        {
          name: "rha_id",
          value: this.rha_id,
        },
      ]).then((response) => {
        this.notes = response.data;
        this.total_rows = this.data.length;
        this.loading = false;
        this.modal_add = false;
      });
    },
    handleSubmit() {
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              Axios.post(
                URL_API + "/rha/catastrophics",
                this.note,
                headersToken(this.login.token)
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.note = {
                    rha_id: this.$attrs.id,
                  };
                  this.getNotes();
                } else console.log(response.data.message);
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    HandleDesactivate(id) {
      this.$swal
        .fire(msgConfirm("Confirma la desactivación del registro?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy(
              "rha/catastrophics",
              this.login.token,
              id,
              this.login.id
            ).then((response) => {
              this.$swal.fire(
                msgAlert(
                  response.success ? "success" : "error",
                  response.message
                )
              );

              response.success
                ? this.getNotes()
                : console.log(response.message);
            });
          }
        });
    },
    editNote(id) {
      show("rha/catastrophics", this.login.token, id).then((response) => {
        this.note = response;
        this.modal_add = true;
      });
    },
    handleDialogAdd() {
      this.modal_add = !this.modal_add;
    },
    addDocumento() {
      this.note.documents.push({
        id: null,
        desciption: "",
        document_id: 0,
        active: true,
      });
    },
    deleteDocument(i) {
      if (this.note.documents[i].id === null) this.note.documents.splice(i, 1);
      else this.note.documents[i].active = false;
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    show("rha", this.login.token, this.rha_id).then((response) => {
      this.rha = response;
    });
    this.getNotes();
    this.note.rha_id = this.$attrs.id;
  },
};
</script>